import React from 'react';
import styles from './style.module.scss';
import Image from 'next/image';

interface FooterStripProps {
  // data: React.ReactNode[];
}
const FooterStrip: React.FC<FooterStripProps> = () => {
  return (
    <>
      <section className={styles.stripsection}>
        <div className={styles.stripinner}>
          <div className="container-1200">
            <div className="wdRow wdRow-cols-1 wdRow-cols-sm-2 wdRow-cols-md-3 wdRow-cols-lg-5">
              <div className="wdCol">
                <div className={styles.stripcard}>
                  <span className={styles.stripimg}>
                    <Image
                      src={'/images/footer/stripimg1.svg'}
                      alt="google-img"
                      width={60}
                      height={60}
                    />
                  </span>
                  <div className={styles.striptext}>
                    <div className={styles.title}>95+</div>
                    <p>Experience Stores Across India </p>
                  </div>
                </div>
              </div>
              <div className="wdCol">
                <div className={styles.stripcard}>
                  <span className={styles.stripimg}>
                    <Image
                      src={'/images/footer/stripimg2.svg'}
                      alt="google-img"
                      width={78}
                      height={60}
                    />
                  </span>
                  <div className={styles.striptext}>
                    <div className={styles.title}>350+</div>
                    <p>Delivery Centers Across India </p>
                  </div>
                </div>
              </div>
              <div className="wdCol">
                <div className={styles.stripcard}>
                  <span className={styles.stripimg}>
                    <Image
                      src={'/images/footer/stripimg3.svg'}
                      alt="google-img"
                      // width={66}
                      // height={60}
                      width="0"
                      height="0"
                      style={{ width: '100%', height: 'auto' }}
                      loading="lazy"
                      quality={100}
                    />
                  </span>
                  <div className={styles.striptext}>
                    <div className={styles.title}>10 Lakh +</div>
                    <p>Satisfied Customers </p>
                  </div>
                </div>
              </div>
              <div className="wdCol">
                <div className={styles.stripcard}>
                  <span className={styles.stripimg}>
                    <Image
                      src={'/images/footer/stripimg4.svg'}
                      alt="google-img"
                      width={60}
                      height={60}
                    />
                  </span>
                  <div className={styles.striptext}>
                    <div className={styles.title}>Lowest Price</div>
                    <p>Guarantee </p>
                  </div>
                </div>
              </div>
              <div className="wdCol">
                <div className={styles.stripcard}>
                  <span className={styles.stripimg}>
                    <Image
                      src={'/images/footer/stripimg5.svg'}
                      alt="google-img"
                      width={66}
                      height={60}
                    />
                  </span>
                  <div className={styles.striptext}>
                    <div className={styles.title}>36 Months*</div>
                    <p>Warranty </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default FooterStrip;
